/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
import Marked from 'marked';
import Link, { LinkProps } from '../../ui-components/link/link';
import Heading from '../../ui-components/heading/heading';
import MainContainer from '../../ui-components/container/container';

import { Box, Flex, Image, Text } from 'rebass';

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle: {
      subtitle: string;
    };
    paragraphs: [
      {
        title: string;
        subtitle: {
          subtitle: string;
        };
        link: LinkProps;
        image: {
          file: {
            url: string;
          };
        };
      }
    ];
  };
  titleAs?: 'h1' | 'h2';
  subtitleAs?: 'h1' | 'h2';
  css?: any;
}

export const query = graphql`
  fragment SideBySideParagraphSection on ContentfulSideBySideParagraphSection {
    title
    paragraphs {
      title
      subtitle {
        subtitle
      }
      link {
        ...Links
      }
      image {
        file {
          url
        }
      }
    }
    internal {
      type
    }
  }
`;

const SideBySideParagraphFoundation: FC<Props &
  JSX.IntrinsicElements['div']> = ({
  className = '',
  titleAs = 'h1',
  subtitleAs = 'h2',
  data: { title, subtitle, paragraphs },
  ...rest
}) => {
  let LENGTH = 0;

  if (paragraphs.length >= 5) {
    LENGTH = paragraphs.length / 2;
  } else {
    LENGTH = paragraphs.length;
  }


  return (
    <MainContainer padding pb={0}>
      <Flex flex="0 1 100%" maxWidth="100%">
        <Heading as="h2" variant="subheading">
          {title}
        </Heading>
        {subtitle && <p>{subtitle.subtitle}</p>}
      </Flex>
      <Flex
        justifyContent="space-between"
        flexWrap={['wrap', 'wrap', 'wrap']}
        flex="0 1 100%"
        maxWidth="100%"
      >
        {paragraphs.map(paragraph => (
          <Box
            key={title}
            width={[1, 1 / LENGTH - 0.01]}
            sx={{ textAlign: 'center' }}
            py={[4, 4, 4]}
          >
            <Image
              src={paragraph.image.file.url}
              height="60px"
              alt={paragraph.title}
            />
            {paragraph.title && (
              <Text as="h3" my={3} variant="body" fontWeight="heading">
                {paragraph.title}
              </Text>
            )}
            {paragraph.subtitle && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: Marked(paragraph.subtitle.subtitle, { breaks: true })
                }}
              />
            )}
            {paragraph.link && (
              <Link
                styledAs={paragraph.link[0].styledAs}
                emphasis={paragraph.link[0].emphasis}
                newTab={paragraph.link[0].newTab}
                slug={paragraph.link[0].slug}
                mr={3}
                {...rest}
              >
                {paragraph.link[0].visibleText}
              </Link>
            )}
          </Box>
        ))}
      </Flex>
    </MainContainer>
  );
};

export default SideBySideParagraphFoundation;
