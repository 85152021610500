import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../ui-components/layout';

import AlternatingImage from '../components/alternating-image/alternating-image';
import FullScreenImage from '../components/full-screen-image/full-screen-image';
import PhoneStripe from '../components/phone-stripe/phone-stripe';
import SEO from '../components/seo/seo';
import Prefooter from '../components/prefooter/prefooter';
import SideBySideParagraph from '../components/side-by-side/side-by-side';
import Quote from '../components/quote/quote';
import Text from '../components/text/text';

interface PageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        githubLink: string;
      };
    };
    contentfulPage: {
      id: number;
      metaTitle: string;
      metaDescription: {
        metaDescription: string;
      };
      slug: string;
      socialMediaImage: {
        file: {
          url: string;
        };
      };
      noIndexnoFollow: boolean;
      language: [];
      section: [];
    };
  };
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        name
        tagline
        githubLink
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription {
        metaDescription
      }
      socialMediaImage {
        file {
          url
        }
      }
      noIndexnoFollow
      language
      slug
      section {
        ...AlternatingImageSection
        ...FullScreenImageSection
        ...PhoneStripeSection
        ...SideBySideParagraphSection
        ...QuoteSection
        ...TextSection
        ...Prefooter
      }
    }
  }
`;

export default class Page extends React.Component<PageProps, {}> {
  public render() {
    const { data } = this.props as PageProps;
    const {
      section,
      metaTitle,
      metaDescription,
      socialMediaImage,
      slug,
      noIndexnoFollow,
      language
    } = data.contentfulPage;

    const components = {
      ContentfulFullScreenImageSection: FullScreenImage,
      ContentfulPhoneStripeSection: PhoneStripe,
      ContentfulAlternatingImageSection: AlternatingImage,
      ContentfulSideBySideParagraphSection: SideBySideParagraph,
      ContentfulTextSection: Text,
      ContentfulPrefooter: Prefooter,
      ContentfulQuote: Quote
    };

    // @ts-ignore
    const ComponentMatch = data => {
      // @ts-ignore
      const MatchingComponent = components[data.internal.type];
      return <MatchingComponent data={data} />;
    };

    return (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDescription.metaDescription}
          image={socialMediaImage.file.url}
          pathname={slug}
          lang={language}
          noIndexNoFollow={noIndexnoFollow}
        />
        <main>
          {section.map((component: any) => (
            <ComponentMatch
              {...component}
              key={component.heading || component.title}
            />
          ))}
        </main>
      </Layout>
    );
  }
}
